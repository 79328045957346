import NeedDtoNT from "./nt/NeedDtoNT";
import {DateTime} from "luxon";
import {NeedVehicleDto} from "./NeedVehicleDto";
import {NeedHumanShiftDto} from "./NeedHumanShiftDto";
import GfDateUtils from "../../components/DatePicker/GfDateUtils";

export class NeedDto {

  id: number;
  serviceCode: string;
  circuit: string;
  startDate: string;
  endDate: string;
  note: string;
  needVehicleDtoList: NeedVehicleDto[];
  needHumanDtoList: NeedHumanShiftDto[];
  hidden: boolean;

  constructor(dtoNt: NeedDtoNT) {
    this.id = dtoNt.id;
    this.serviceCode = dtoNt.serviceCode;
    this.circuit = dtoNt.circuit;
    this.note = dtoNt.note;
    this.startDate = dtoNt.startDate;
    this.endDate = dtoNt.endDate;
    this.needVehicleDtoList = dtoNt.needVehicleDtoList.map(a => new NeedVehicleDto(a));
    this.needHumanDtoList = dtoNt.needHumanDtoList.map(a => new NeedHumanShiftDto(a));
    this.hidden = dtoNt.hidden;
  }
}
