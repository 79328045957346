import AuthorizeApi from "../AuthorizeApi";
import {OperationResult} from "../entity/OperationResult";
import {IntegrationOperationResult} from "../entity/response/IntegrationOperationResult";
import {ProcessStatusResponse} from "../entity/response/ProcessStatus";
import {ContainerVehicleResponse, DailyContainerVehicle} from "../entity/response/nt/ContainerVehicleResponseNt";

export default class ContainerVehicleApi {

  private base: string = '/container-vehicles';

  private authApi: AuthorizeApi = new AuthorizeApi();

  public async updateContainerVehicles(start: string, end: string,
                                       onSuccess: () => void) {
    await this.authApi.post<void, IntegrationOperationResult>(`${this.base}/run/${start}/${end}`, null)
      .then((w) => {
        if (w.result == OperationResult.OK) {
          onSuccess();
        }
      })
  }

  public async getStatus(onIdle: () => void, onWait: () => void) {
    await this.authApi.get<ProcessStatusResponse>(`${this.base}/run`)
      .then((w) => {
        if (w.status == "IDLE") {
          onIdle();
        } else {
          onWait();
        }
      })
  }

  public async waitForProcessToFinish() {
    const r = await this.authApi.get<ProcessStatusResponse>(`${this.base}/run`)
    if (r.status != "IDLE") { 
      await new Promise(resolve => setTimeout(resolve, 2500));
      await this.waitForProcessToFinish();
    }

    return r;
  }

  public async update(containerVehicle: DailyContainerVehicle, onSuccess: () => void = () => {}) {
    await this.authApi.put<any, IntegrationOperationResult>(this.base, containerVehicle)
      .then((w) => {
        if (w.result == OperationResult.OK) {
          onSuccess();
        }
      })
  }

  public async create(containerVehicle: DailyContainerVehicle, onSuccess: () => void = () => {}): Promise<any> {
    return await this.authApi.post<any, IntegrationOperationResult>(this.base, containerVehicle)
      .then((w) => {
        if (w.result == OperationResult.OK) {
          onSuccess();
        }

        return w;
      })
  }

  public async getDistinctPaginatedContainerVehiclesFromDate(dateString: string,
                                                             limit: number,
                                                             skip: number,
                                                             searchString: string = "") {
    let response: ContainerVehicleResponse;
    const query =  searchString ? `?search=${searchString}` : ""
    await this.authApi.get<ContainerVehicleResponse>(`${this.base}/from/${dateString}/${limit}/${skip}${query}`)
      .then((resp) => {
        response = resp;
      });
    return response;
  }

  public async getDistinctContainerVehiclesForDate(dateString: string):Promise<ContainerVehicleResponse> {
    let response: ContainerVehicleResponse;
    await this.authApi.get<ContainerVehicleResponse>(`${this.base}/${dateString}`)
      .then((resp) => {
        response = resp;
      });
    return response;
  }
}
