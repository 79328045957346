import AuthorizeApi from "../AuthorizeApi";
import {VehicleResponse} from "../entity/response/VehicleResponse";
import {VehicleResponseNt} from "../entity/response/nt/VehicleResponseNt";
import {VehicleDto} from "../entity/VehicleDto";
import {ReadRequest} from "../entity/request/ReadRequest";
import {DateTime} from "luxon";
import GfDateUtils from "../../components/DatePicker/GfDateUtils";
import {ReadDateRequest} from "../entity/request/ReadDateRequest";

export default class VehicleApi {

  private base: string = '/vehicle';

  private authApi: AuthorizeApi = new AuthorizeApi();

  public async getVehicleById(id: number) {
    let response: VehicleResponse;
    await this.authApi.get<VehicleResponseNt>(`${this.base}/` + id).then((resp) => {
      response = new VehicleResponse(resp)
    });
    return response;
  }

  public async save(vehicle: VehicleDto) {
    let response: VehicleResponse;
    this.authApi.post<VehicleDto, VehicleResponseNt>(`${this.base}/save`, vehicle).then((resp) => {
      response = new VehicleResponse(resp);
    })
    return response;
  }

  public async update(vehicle: VehicleDto) {
    let response: VehicleResponse;
    response = await this.authApi.put<VehicleDto, VehicleResponseNt>(`${this.base}/save`, vehicle);
    return response;
  }

  async getOrSearch(searchKey: string, elementsPerPage: number, skip: number) {
    let workerResponse: VehicleResponse;
    await this.authApi.post <ReadRequest, VehicleResponseNt>(`${this.base}`,
      {skip: skip, count: elementsPerPage, searchKey: searchKey})
      .then((w) => {
        workerResponse = new VehicleResponse(w)
      });
    return workerResponse;
  }

  async getOrSearchValid(date: DateTime, searchKey: string, elementsPerPage: number, skip: number, orderByName: boolean) {
    let workerResponse: VehicleResponse;
    await this.authApi.post <ReadDateRequest, VehicleResponseNt>(`${this.base}/valid`,
      {skip: skip, count: elementsPerPage, searchKey: searchKey,
        date: date.toFormat(GfDateUtils.STORED_DATE_FORMAT), orderByCatName: orderByName})
      .then((w) => {
        workerResponse = new VehicleResponse(w)
      });
    return workerResponse;
  }


  async getByName(vehicleName: string) {
    let workerResponse: VehicleResponse;
    await this.authApi.get <VehicleResponseNt>(`${this.base}/name/${vehicleName}`)
      .then((w) => {
        workerResponse = new VehicleResponse(w)
      });
    return workerResponse;
  }
}
