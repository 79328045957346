import { useDraggable } from "@dnd-kit/core";
import "./WorkerCard.scss";
import React from "react";
import {CSS, Transform} from '@dnd-kit/utilities';

import { WorkerDto } from "../../../api/entity/WorkerDto";
import { minutesToTimeString, timeStringToMinutes } from "../DailyOverviewUtils";

interface IProps {
  worker: {
    turn: string
    id: string;
    name: string;
    workerid: string;
    suffix: string;
    servicestart: string;
    dto: WorkerDto;
    plannedTurn?: string;
    shiftstart?: string;
    shiftend?: string;
    overrideTurnTime?: boolean;
  },
  containerVehicle?: any;
  extraDragTransform?: Transform;
}



export default function WorkerCard({ worker, extraDragTransform, containerVehicle }: IProps) {
  var cardId = worker.id+worker.suffix;
  const {attributes, listeners, setNodeRef, transform, isDragging} = useDraggable({
    id: cardId,

  });

  if (isDragging && extraDragTransform) {
    //Merge the two transforms
    transform.x += extraDragTransform.x
    transform.y += extraDragTransform.y
  }


  const style = {
    zIndex: isDragging ? 1000000 : 0,
    transform: CSS.Translate.toString(transform),
  };


            /*
            worker: {
              serviceend: '08:00:00',
              servicestart: '06:00:00',
              shiftend: '13:30:00',
              shiftstart: '06:00:00',
              turn: 'M84',
              vehicle: null,
              workerid: 1416,
              workername: null
            }*/

  var workerClass = "card";
  if (containerVehicle?.category) {
    var categoryClass = containerVehicle.category.toLowerCase();
    if (categoryClass == "em+") {
      categoryClass = "emplus";
    }

    workerClass += " " + categoryClass;
  }

  if (worker.dto?.role) {
    workerClass += " role_" + worker.dto.role.toLowerCase();
  }

  if (worker.dto?.circuit) {
    workerClass += " circuit_" + worker.dto.circuit.toLowerCase();
  }



  let showTopLine = worker.servicestart && containerVehicle
    && containerVehicle?.containervehicle != "LIBERO|SERVICE";

  return <button className={workerClass} id={cardId} ref={setNodeRef}
       style={style} {...listeners} {...attributes}>

      { showTopLine && (
        <div className={"topLine" + (worker.overrideTurnTime ? " overrideTurnTime" : "")}>
          <div className="workerId"> {worker.workerid} </div>
          <div className="workerTime"> {getWorkerTime(worker)} </div>
        </div>)
      }

      <div className="bottomLine">
        <div className="turn"> {worker.turn} </div>
        { worker.dto?.skill && <div className="workerAbilitazione"> {worker.dto?.skill} </div> }
        { worker.dto?.height && <div className="workerHeight"> {worker.dto?.height.slice(0,1)} </div> }
        <div className="workerName">{ worker.name } </div>
        { (worker.plannedTurn && (worker.plannedTurn != worker.turn)) && <div className="scheduledTurn"> {worker.plannedTurn} </div> }
      </div>
    </button>
    ;

  function getWorkerTime(worker: any) {
    var label = "";
    var stimestart = timeStringToMinutes (worker.servicestart);
    var stimeend = timeStringToMinutes(worker.serviceend);

    if (stimeend < stimestart) {
      stimeend += 24*60;
    }


    if (worker.shiftstart && worker.shiftend) {
      var turnstart = timeStringToMinutes(worker.shiftstart);
      var turnend = timeStringToMinutes(worker.shiftend);

      if (turnend < turnstart) {
        turnend += 24*60;
      }

      if (stimestart < turnstart) {
        stimestart = turnstart;
      }

      if (turnend < stimeend) {
        stimeend = turnend;
      }
    }

    var timeStart = minutesToTimeString(stimestart);
    var timeEnd = minutesToTimeString(stimeend);


    if (timeStart != null && timeEnd != null) {
      if (timeStart == "23:59") {
        timeStart="00:00";
      }

      if (timeEnd == "23:59") {
        timeEnd="00:00";
      }


      label = timeStart + " - " + timeEnd;
    }


    var turn = worker.turn;
    var turnStart = worker.shiftstart;
    var turnEnd = worker.shiftend;

    if (turnStart != null && turnEnd != null) {
      var sturnstart = timeStringToMinutes(turnStart);
      var sturnend = timeStringToMinutes(turnEnd);

      if (sturnend < sturnstart) {
        sturnend += 24*60;
      }

      if (stimestart != sturnstart || stimeend != sturnend) {

        if (label != "") {
          label += " "
        }

        turnStart = turnStart.split(":").slice(0, 2).join(":");
        turnEnd = turnEnd.split(":").slice(0, 2).join(":");

        if (turnStart == "23:59") {
          turnStart="00:00";
        }
        if (turnEnd == "23:59") {
          turnEnd="00:00";
        }

        label += "(" + turn + ": " + turnStart + " - " + turnEnd + ")";
      }
    }

    return label;
  }
}
