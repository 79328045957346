import React, {BaseSyntheticEvent} from "react";
import "./NeedModal.scss";
import I18n from "../../lib/I18n";
import {NeedDto} from "../../api/entity/NeedDto";
import NeedValidator from "../../api/worker/validator/NeedValidator";
import Button from "../../components/Button/Button";
import TextInput from "../../components/TextInput/TextInput";
import Select from "../../components/Select/Select";
import Page from "../Page";
import {DateTime} from "luxon";
import IconBackBtn from "../../icons/IconBackBtn";
import {GfDatePicker} from "../../components/DatePicker/GfDatePicker";
import NeedManagementApi from "../../api/need/NeedManagementApi";
import {OperationResult} from "../../api/entity/OperationResult";
import {Pages} from "../../router/Pages";
import {VehicleEntry} from "../../components/Modal/NeedModal/VehicleEntry/VehicleEntry";
import update from "immutability-helper";
import ShiftUtils from "../../utils/ShiftUtils";
import {NeedVehicleDto} from "../../api/entity/NeedVehicleDto";
import {HumanShiftEntry} from "../../components/Modal/NeedModal/HumanEntry/HumanShiftEntry";
import {NeedHumanShiftDto} from "../../api/entity/NeedHumanShiftDto";
import ShiftRegistryApi from "../../api/shift/ShiftRegistryApi";
import {ShiftDto} from "../../api/entity/ShiftDto";
import {ModalType} from "../../components/Modal/ModalType";
import GfDateUtils from "../../components/DatePicker/GfDateUtils";
import {FocusPanel} from "../../components/FocusPanel/FocusPanel";
import ContainerVehicleApi from "../../api/container-vehicle/ContainerVehicleApi";
import MonthlyPlanningApi from "../../api/planning/MonthlyPlanningApi";
import {StatusEnum} from "../../api/engine/EngineApi";
import {ContainerVehicleLogicUtils} from "../../api/container-vehicle/ContainerVehicleLogicUtils";
import {ConfirmModal} from "../../components/Modal/ConfirmModal/ConfirmModal";
import {TimeOffUtils} from "../../api/timeOff/TimeOffApi";


interface State {
  isTouched: boolean,
  needDto: NeedDto,
  deletedHumanNeeds: NeedHumanShiftDto[],
  deletedVehicleNeeds: NeedVehicleDto[],
  needModalErrors: NeedModalErrors,
  shiftList: ShiftDto[],
  shiftListWithoutDayOffAndNotWorkingDay: ShiftDto[],
  isLoadingToShow: boolean,
  nextMonthPlanning: StatusEnum,
  isConfirmModalOpen: boolean,
  confirmModal: {
    indexForDeletion: number,
    deletionEntity: DeletionEntity,
    onDelete: (x) => void;
  }
}

enum DeletionEntity {
  VEHICLE, HUMAN
}

export interface NeedVehicleErrors {
  fromTimeError: string;
  toTimeError: string;
  weekTimeErrors: string[];
  weekEndTimeErrors: string[];
}

export interface NeedHumanErrors {
  weekTimeErrors: string[];
  weekEndTimeErrors: string[];
}

export interface NeedModalErrors {
  codeService: string;
  fromDate: string;
  toDate: string;
  emptyVehicleNeed: string;
  needVehicleErrors: NeedVehicleErrors[] // in sync with the vehicle entries
  needHumanErrors: NeedHumanErrors[] // in sync with the human entries
}

export class NeedModal extends Page<{}, State> {

  private readonly TIMEOUT_WAIT_MS = 2500;

  private modalType = ModalType.CREATE;
  private planningApi = new MonthlyPlanningApi();
  private needManagerApi = new NeedManagementApi();
  private shiftManagerApi = new ShiftRegistryApi();
  private containerVehiclesApi = new ContainerVehicleApi();
  private shiftUtils = new ShiftUtils();

  private readonly MAX_SHIFT_LOADED_NUMBER = 200;

  private readonly ID = "id";

  /************************************************
   * CONSTRUCTOR
   ************************************************/
  constructor(props) {
    super(props);

    this.state = {
      isTouched: false,
      isConfirmModalOpen: false,
      deletedHumanNeeds: [],
      shiftListWithoutDayOffAndNotWorkingDay: [],
      deletedVehicleNeeds: [],
      confirmModal: {
        indexForDeletion: 0,
        deletionEntity: DeletionEntity.HUMAN,
        onDelete: () => {
        }
      },
      needDto: {
        circuit: this.shiftUtils.CIRCUIT_DTO[0], serviceCode: "",
        endDate: DateTime.local(2099, 12, 31).toFormat(GfDateUtils.STORED_DATE_FORMAT),
        startDate: DateTime.now().toFormat(GfDateUtils.STORED_DATE_FORMAT),
        id: null,
        note: "",
        needVehicleDtoList: [],
        needHumanDtoList: [],
        hidden: false
      },
      isLoadingToShow: false,
      needModalErrors: NeedValidator.getEmptyErrors(),
      shiftList: [],
      nextMonthPlanning: StatusEnum.NO_PLANNING
    }
  }

  async componentDidMount() {
    const pathId = new URLSearchParams(this.props.match.params).get(this.ID);
    this.modalType = pathId != null ? ModalType.MODIFY : ModalType.CREATE;

    this.planningApi.getByDate(DateTime.now().startOf('month').plus({'month': 1}))
      .then((resp) => {
        this.setState({nextMonthPlanning: resp.status});
      })

    let shifts = await this.shiftManagerApi.get("", this.MAX_SHIFT_LOADED_NUMBER, 0);
    let filteredShifts = shifts.content.filter(a => !TimeOffUtils.isNonWorkingDayOrTimeOff(a.shiftCode))
    if (this.modalType == ModalType.MODIFY) {
      let load = await this.needManagerApi.getById(pathId);

      this.setState({
        needDto: load.content[0],
        shiftList: shifts.content,
        shiftListWithoutDayOffAndNotWorkingDay: filteredShifts,
        needModalErrors: {
          ...this.state.needModalErrors,
          needVehicleErrors: load.content[0].needVehicleDtoList.map(a => NeedValidator.getEmptyVehicleError()),
          needHumanErrors: load.content[0].needHumanDtoList.map(a => NeedValidator.getEmptyHumanError())
        }
      });
    } else {
      this.setState({
        shiftListWithoutDayOffAndNotWorkingDay: filteredShifts,
        shiftList: shifts.content
      })
    }
  }

  private async onSubmit(event: BaseSyntheticEvent) {
    event.preventDefault();
    let errors = NeedValidator.validate(this.state.needDto, this.modalType).then(a => {
        this.setState({needModalErrors: a});
        if (NeedModal.isAnyErrorPresent(a)) {
          return;
        }
        this.createAndConnectVehiclesAndHumanNeeds();
      }
    );
  }

  private async createAndConnectVehiclesAndHumanNeeds() {
    this.setState({isLoadingToShow: true});
    let needResponsePromise = await this.needManagerApi.upsert(this.state.needDto);
    if (needResponsePromise.status == OperationResult.OK) {
      const savedNeed = needResponsePromise.content[0];
      let needId = savedNeed.id;
      let vehiclePromise = await this.needManagerApi.connectVehicleToNeed(
        {
          needId: needId,
          needVehicles: this.state.needDto.needVehicleDtoList,
          deletedVehicles: this.state.deletedVehicleNeeds
        }
      );

      let humanPromise = await this.needManagerApi.connectHumanToNeed(
        {
          needId: needId,
          needHuman: this.state.needDto.needHumanDtoList,
          deletedNeedHuman: this.state.deletedHumanNeeds
        }
      )
      const response = await Promise.all([
        vehiclePromise,
        humanPromise
      ]);
      const [vehiclePromiseResp, humanPromiseResp] = response;
      if (vehiclePromiseResp.status == OperationResult.OK &&
        humanPromiseResp.status == OperationResult.OK) {
        // Generate container vehicles when creating a new entry or applying changes to it
        if (this.state.isTouched || this.state.needDto.id == null) {
          const startEndDateStrings = ContainerVehicleLogicUtils.getStartEndDateStrings(this.state.nextMonthPlanning);
          await this.containerVehiclesApi.updateContainerVehicles(
            startEndDateStrings.start,
            startEndDateStrings.end,
            () => {
              this.waitForProcessToFinish();
              return;
            });
        } else {
          this.setState({isLoadingToShow: false});
          this.goBack();
        }
      } else if (this.modalType == ModalType.CREATE) {
        await this.needManagerApi.delete(needId);
      }
      return;
    }
  }

  private waitForProcessToFinish() {
    setTimeout(() => {
      this.containerVehiclesApi.getStatus(() => {
          this.setState({isLoadingToShow: false});
          this.goToWithSuccess(Pages.NEED_MANAGEMENT);
        },
        () => {
          this.waitForProcessToFinish();
        });
    }, this.TIMEOUT_WAIT_MS);
  }

  private addHumanShift() {
    this.signalDirtyForm();
    const emptyWeek = ["0", "0", "0", "0", "0", "0", "0"];
    this.setState({
      ...this.state,
      needModalErrors: {
        ...this.state.needModalErrors,
        needHumanErrors: update(this.state.needModalErrors.needHumanErrors,
          {
            $push: [NeedValidator.getEmptyHumanError()]
          })
      }, needDto: {
        ...this.state.needDto,
        needHumanDtoList:
          update(this.state.needDto.needHumanDtoList,
            {
              $push: [
                {
                  id: null,
                  timeSpan: "00:00-00:00",
                  turnCode: "",
                  turnId: 0,
                  enablement: this.shiftUtils.ENABLEMENT_DTO[0],
                  moss: false,
                  role: "",
                  needId: this.state.needDto.id,
                  numberVehiclesFestive: [...emptyWeek],
                  numberVehiclesWorkdays: [...emptyWeek]
                }
              ]
            }),
      },
    });
  }

  private addVehicleShift() {
    this.signalDirtyForm();
    const emptyWeek = ["0", "0", "0", "0", "0", "0", "0"];
    this.setState({
      ...this.state,
      needModalErrors: {
        ...this.state.needModalErrors,
        needVehicleErrors: update(this.state.needModalErrors.needVehicleErrors,
          {
            $push: [NeedValidator.getEmptyVehicleError()]
          })
      },
      needDto: {
        ...this.state.needDto,
        needVehicleDtoList:
          update(this.state.needDto.needVehicleDtoList,
            {
              $push: [
                {
                  id: null,
                  numberVehiclesFestive: [...emptyWeek],
                  category: this.shiftUtils.NEED_TOPOLOGY_DTO[0],
                  numberVehiclesWorkdays: [...emptyWeek],
                  publishing: this.shiftUtils.PUBLISHING_DTO[0],
                  parkingDescription: this.shiftUtils.PLACE_DTO[0],
                  fromTime: "00:00", toTime: "00:00",
                  convention: this.shiftUtils.NEED_CONVENTION_DTO[0],
                  needId: this.state.needDto.id
                }
              ]
            }),
      },
    })
  }

  private goToWithSuccess(location: string) {
    this.props.history.push(location, {"success": true});
  }

  private goToWithError(location: string) {
    this.props.history.push(location, {"success": false});
  }


  private goBack() {
    this.props.history.push(Pages.NEED_MANAGEMENT);
  }

  private static isAnyErrorPresent(shiftModalErrors: NeedModalErrors) {
    return !NeedValidator.isNoError(shiftModalErrors);
  }

  private deleteVehicleAt(index: number) {
    this.signalDirtyForm();
    let {needDto, needModalErrors, deletedVehicleNeeds} = this.state;
    const deletedNeedVehicle = needDto.needVehicleDtoList.splice(index, 1);
    needModalErrors.needVehicleErrors.splice(index, 1);
    const needVehicleDto = deletedNeedVehicle[0];
    // only if it is saved
    if (needVehicleDto.id) {
      deletedVehicleNeeds.push(needVehicleDto)
    }
    this.setState({needDto, needModalErrors, deletedVehicleNeeds});
  }

  private deleteHumanConnectionAt(index: number) {
    this.signalDirtyForm();
    let {needDto, needModalErrors, deletedHumanNeeds} = this.state;
    const delHumanNeed = needDto.needHumanDtoList.splice(index, 1);
    needModalErrors.needHumanErrors.splice(index, 1);
    const humanNeedDto = delHumanNeed[0];
    // only if it is saved
    if (humanNeedDto.id) {
      deletedHumanNeeds.push(humanNeedDto);
    }
    this.setState({needDto, needModalErrors, deletedHumanNeeds});
  }

  private onVehicleChange(index: number, v: NeedVehicleDto) {
    this.signalDirtyForm();
    const {needDto} = this.state;
    needDto.needVehicleDtoList[index] = v;
    this.setState({needDto});
  }

  private onHumanChange(index: number, v: NeedHumanShiftDto) {
    this.signalDirtyForm();
    const {needDto} = this.state;
    needDto.needHumanDtoList[index] = v;
    this.setState({needDto});
  }

  // When saving dirty
  private signalDirtyForm() {
    this.setState({isTouched: true});
  }

  private getDeleteMessage() {
    const confirmModalState = this.state.confirmModal;
    const isHumanDeletion = confirmModalState.deletionEntity == DeletionEntity.HUMAN;
    const needState = this.state.needDto;
    if (isHumanDeletion) {
      return I18n.get().NeedManagement.addNewNeedManagement.human.deleteBody + needState.needHumanDtoList[confirmModalState.indexForDeletion].turnCode + "'?";
    }
    return I18n.get().NeedManagement.addNewNeedManagement.vehicle.deleteBody + needState.needVehicleDtoList[confirmModalState.indexForDeletion].category + "'?";
  }

  /************************************************
   * LIFECYCLE
   ************************************************/
  render() {
    return (
      <div>
        <FocusPanel className={""} text={I18n.get().NeedManagement.savingMessage}
                    show={this.state.isLoadingToShow}/>
        <FocusPanel className={""} text={""}
                    show={this.state.isConfirmModalOpen}/>

        {this.state.isConfirmModalOpen ?
          <ConfirmModal title={I18n.get().NeedManagement.needDeleteMessage} body={this.getDeleteMessage()}
                        onClick={() => {
                          this.setState({isConfirmModalOpen: false});
                          this.state.confirmModal
                            .onDelete(this.state
                              .confirmModal
                              .indexForDeletion);
                        }} onCancel={() => {
            this.setState({isConfirmModalOpen: false})
          }}/> : null}

        <div className={"NeedModal inner-content"}>
          <div className={"top"}>
            <div className={"above-title"} onClick={() => {
              this.goBack()
            }}>
              <IconBackBtn width={24} height={24}/>
              <p>
                {I18n.get().NeedManagement.addNewNeedManagement.backButton}
              </p>
            </div>
            <div className={"up-title"}>
              <h1>{this.modalType == ModalType.MODIFY ? I18n.get().NeedManagement.modifyNeed : I18n.get().NeedManagement.addNewNeed}</h1>
            </div>
            <div className={"up-controls"}>
              <Button onClick={(event) => this.onSubmit(event)}
                      className={"add-btn"}>
                {I18n.get().NeedManagement.addNewNeedManagement.actionButton}
              </Button>
            </div>
            {this.state.needModalErrors.emptyVehicleNeed != "" ?
              <p className={"error"}>
                {this.state.needModalErrors.emptyVehicleNeed}</p>
              : null}
          </div>

          <div className={"form-content"}>
            <form onSubmit={(event) => this.onSubmit(event)}>
              <div className={"form"}>
                <div className={"top-row"}>
                  <div className={"control"}>
                    <TextInput disabled={this.modalType == ModalType.MODIFY}
                               defaultValue={this.state.needDto.serviceCode}
                               onChange={(code) => {
                                 this.setState({
                                   needDto: {...this.state.needDto, serviceCode: code.toUpperCase()},
                                   needModalErrors: {...this.state.needModalErrors, codeService: ""}
                                 })
                               }}
                               errorMessage={this.state.needModalErrors.codeService}
                               label={I18n.get().NeedManagement.addNewNeedManagement.serviceCode} className={"row-w"}/>
                  </div>
                  <div className={"control"}>
                    <Select
                      defaultValue={this.shiftUtils.CIRCUIT_DTO.indexOf(this.state.needDto.circuit)}
                      items={this.shiftUtils.CIRCUIT_DTO} label={I18n.get().NeedManagement.addNewNeedManagement.circuit}
                      className={"height"}
                      onChange={(index) => {
                        this.setState({
                          needDto:
                            {
                              ...this.state.needDto,
                              circuit: this.shiftUtils.CIRCUIT_DTO[index]
                            }
                        })
                      }
                      }
                    />
                  </div>
                  <div className={"control"}>
                    <GfDatePicker label={I18n.get().NeedManagement.addNewNeedManagement.start}
                                  onChange={(date) => {
                                    this.setState({
                                      needModalErrors: {
                                        ...this.state.needModalErrors,
                                        toDate: "",
                                        fromDate: ""
                                      }
                                    })
                                    this.setState({
                                      needDto: {
                                        ...this.state.needDto,
                                        startDate: date.toFormat(GfDateUtils.STORED_DATE_FORMAT)
                                      },
                                      isTouched: true
                                    })
                                  }}
                                  errorMessage={this.state.needModalErrors.fromDate}
                                  date={DateTime.fromFormat(this.state.needDto.startDate, GfDateUtils.STORED_DATE_FORMAT)}/>
                  </div>
                  <div className={"control"}>
                    <GfDatePicker label={I18n.get().NeedManagement.addNewNeedManagement.end}
                                  onChange={(date) => {
                                    this.setState({
                                      needModalErrors: {
                                        ...this.state.needModalErrors,
                                        toDate: "",
                                        fromDate: ""
                                      }
                                    })
                                    this.setState({
                                      needDto: {
                                        ...this.state.needDto,
                                        endDate: date.toFormat(GfDateUtils.STORED_DATE_FORMAT)
                                      },
                                      isTouched: true
                                    })
                                  }}
                                  errorMessage={this.state.needModalErrors.toDate}
                                  date={DateTime.fromFormat(this.state.needDto.endDate, GfDateUtils.STORED_DATE_FORMAT)}/>
                  </div>
                </div>
                <div className={"top-row"}>
                  <div className={"control-full"}>
                    <TextInput defaultValue={this.state.needDto.note}
                               onChange={(note) => {
                                 this.setState({
                                   needDto: {...this.state.needDto, note: note},
                                   needModalErrors: {...this.state.needModalErrors, codeService: ""}
                                 })
                               }}
                               label={I18n.get().NeedManagement.addNewNeedManagement.notes} className={"row-w"}/>
                  </div>
                </div>
              </div>
              <div className={"form"}>
                <h2>{I18n.get().NeedManagement.addNewNeedManagement.vehicle.needsVehicles}</h2>
                {this.state.needDto.needVehicleDtoList.map((a, index) =>
                  <VehicleEntry key={`${this.state.needDto.needVehicleDtoList.length}_${index}`}
                                onChange={(vehicleDto) => {
                                  this.setState({isTouched: true});
                                  this.onVehicleChange(index, vehicleDto);
                                }}
                                shiftUtils={this.shiftUtils}
                                onDelete={() => {
                                  this.setState({
                                    isTouched: true, isConfirmModalOpen: true, confirmModal: {
                                      onDelete: (index) => this.deleteVehicleAt(index), indexForDeletion: index,
                                      deletionEntity: DeletionEntity.VEHICLE
                                    }
                                  });
                                }}
                                vehicleErrors={this.state.needModalErrors.needVehicleErrors[index]}
                                vehicleDto={a}/>)
                }
              </div>
              <div className={"form row"}>
                  <span
                    className={"add-vehicle-btn"}
                    onClick={() => this.addVehicleShift()}>+ {I18n.get().NeedManagement.addNewNeedManagement.vehicle.needsVehicles}</span>
              </div>

              <div className={"form row"}>
                <h2>{I18n.get().NeedManagement.addNewNeedManagement.human.title}</h2>
                {this.state.needDto.needHumanDtoList.map((a, index) =>
                  <HumanShiftEntry key={`${this.state.needDto.needHumanDtoList.length}_${index}`}
                                   onChange={(needHumanShift) => {
                                     this.setState({isTouched: true});
                                     this.onHumanChange(index, needHumanShift);
                                   }}
                                   shiftUtils={this.shiftUtils}
                                   onDelete={() => {
                                     this.setState({
                                       isTouched: true, isConfirmModalOpen: true, confirmModal: {
                                         onDelete: (index) => this.deleteHumanConnectionAt(index),
                                         indexForDeletion: index, deletionEntity: DeletionEntity.HUMAN
                                       }
                                     });
                                   }}
                                   shifts={this.state.shiftList}
                                   number={`${this.state.needDto.needHumanDtoList.length}_${index}`}
                                   needHumanErrors={this.state.needModalErrors.needHumanErrors[index]}
                                   needHumanShiftDto={a}/>)
                }

              </div>
              <div className={"form"}>
                {this.state.shiftList.length > 0 ?
                  <span
                    className={"add-human-shift-btn"}
                    onClick={() => {
                      this.setState({isTouched: true});
                      this.addHumanShift()
                    }}>+ {I18n.get().NeedManagement.addNewNeedManagement.human.addConnection}</span>
                  : null}
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}
