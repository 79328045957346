import { WorkerServiceTurnModal } from "../../components/Modal/WorkerServiceTurnModal/WorkerServiceTurnModal";





export interface IWorkers {
  LIBERO?: any[];
  RIPOSO?: any[];
  REPERIBILE?: any[];
  SMONTO?: any[];
  FERIE?: any[];
  MALATTIA?: any[];
  OTHERS?: any[];
  EXTRA?: any[];
  GESTIONE?: any[];
}


export function distictValues<T>(array: T[]): T[] {
  return [...new Set(array)];
}


export function getFilteredWorkers(workers: any[], workersMap: Map<string, any>, circuitFilter: string) {
  if (circuitFilter) {
    var circuitWorkers = [...workersMap.values()]
      .filter((worker) => worker.circuit == circuitFilter)
      .map((worker) => worker.id);
    return workers.filter((worker) => circuitWorkers.includes(worker.workerid));
  }
  return workers;
}

//REPERIBILE = Service: REPERIBILE -> CV: REPERIBILE
//MALATTIA = SERVICE AR - Turn: AR -> CV: -
//RIPOSO = Service: RIPOSO -> CV: RIPOSO -> TURN: R, RN
//SMONTO = Service: SMONTO -> CV: SMONTO -> TURN: S
//FERIE = Service: FERIE -> CV: FERIE -> TURN: F
//EXTRA = Service: GR -> CV: - -> TURN: GR  oppure Service: "LIBERO" -> CV: - -> TURN: FT
//GESTIONE = Service: GE -> CV: - -> TURN: GE
//LIBERO = Service: LIBERO -> CV: - -> TURN: not(FT)

export function splitWorkersByServices(dailyWorkers: any[]): IWorkers {
  dailyWorkers = dailyWorkers.filter((x) => x.workerid != null);
  var servicesWorkers: Map<string, any[]> = new Map<string, any[]>();


  var extraServices =  [ "RIPOSO", "SMONTO" ]
  extraServices.forEach((x) => {
    servicesWorkers.set(x, dailyWorkers.filter((y) => y.service == x));
  });





  servicesWorkers.set("MALATTIA", dailyWorkers.filter((y) => y.service == "AR" || (y.turn == "AR" && y.service == "ASSENZA GIORNALIERA")));
  servicesWorkers.set("FERIE", dailyWorkers.filter((y) => y.service == "FERIE"));
  servicesWorkers.set("EXTRA", dailyWorkers.filter((y) => y.service == "GR" || y.service == "FT" || y.turn == 'FT' || y.turn == 'GR' || y.turn == 'C01'));
  servicesWorkers.set("GESTIONE", dailyWorkers.filter((y) => y.service == "GE" || y.turn == 'GE'));



  let visitedIds = new Set();
  servicesWorkers.forEach((w, svc) => {
    w.forEach((dw) => {
      if (visitedIds.has(dw.id)) {
        console.log("Duplicate worker id: ", dw.id);
      }
      visitedIds.add(dw.id);
    });
  });

  servicesWorkers.set("LIBERO", dailyWorkers.filter((y) =>

    !visitedIds.has(y.id) &&
      (
        y.service == "LIBERO"
        //Devo considerare reperibili liberi anche i reperibili che non hanno servizio ( = servizio reperibile E turno reperibile)
        || (isPartialReperibility(y.turn) && y.service?.startsWith("REP"))
      )
  ));

  servicesWorkers.get("LIBERO").forEach((x) => { visitedIds.add(x.id); });

  servicesWorkers.set("REPERIBILE", dailyWorkers.filter((y) =>
    //servizio reperibile O turno reperibile
    y.service?.startsWith("REP")
    || isPartialReperibility(y.turn)
  ));

  servicesWorkers.get("REPERIBILE").forEach((x) => { visitedIds.add(x.id); });



  servicesWorkers.set("OTHERS", dailyWorkers
    .filter((y) => !visitedIds.has(y.id)));



  const workers = {
    "LIBERO": servicesWorkers.get("LIBERO"),
    "RIPOSO": servicesWorkers.get("RIPOSO"),
    "REPERIBILE": servicesWorkers.get("REPERIBILE"),
    "SMONTO": servicesWorkers.get("SMONTO"),
    "FERIE": servicesWorkers.get("FERIE"),
    "MALATTIA": servicesWorkers.get("MALATTIA"),
    "OTHERS": servicesWorkers.get("OTHERS"),
    "EXTRA": servicesWorkers.get("EXTRA"),
    "GESTIONE": servicesWorkers.get("GESTIONE"),
  }

  return workers;
}




export function timeStringToMinutes(timeString: string) {
  if (!timeString) {
    return 0;
  }

  var time = timeString.match(/(\d+)(?::(\d\d))?\s*(p?)/);
  var hours = parseInt(time[1], 10);
  var minutes = parseInt(time[2], 10) || 0;
  if (time[3]) {
    hours += hours === 12 ? 0 : 12;
  }
  return hours * 60 + minutes;
}

export function objIsWorker(obj: any): boolean {
  return obj?.hasOwnProperty("workerid") ?? false;
}
export function objIsVehicle(obj: any): boolean {
  return obj?.hasOwnProperty("vehicleId") ?? false;
}

//pomeriggio tutti i turni che iniziano dopo le 12 o finiscono tra le 12 e le 18
//sera tutti i turni che iniziano dopo le 18 o finiscono tra le 18 e le 24

export function objIsShift(obj: any): boolean {
  return obj?.hasOwnProperty("shiftCode") ?? false;
}


export function getStartEndWithShiftMap(shiftMap: Map<string, any>, workerOrContainerVehicle: any, containerVehicle: any = null, vehicleNeed: any = null) {
  var realShiftStart = 0;
  var realShiftEnd = 0;


  var start = 0, end = 0, startCv = 0, endCv = 24*60;
  if (containerVehicle) {
    startCv = timeStringToMinutes(containerVehicle.startshift);
    endCv = timeStringToMinutes(containerVehicle.endshift);


    if (endCv < startCv) {
      endCv += 24*60;
    }
  }
  else {
    endCv = 0;
  }

  if (vehicleNeed && !containerVehicle) {
    var startVneed = timeStringToMinutes(vehicleNeed.fromTime);
    var endVneed = timeStringToMinutes(vehicleNeed.toTime);

    if (endVneed < startVneed) {
      endVneed += 24*60;
    }

    if (startCv == 0 && endCv == 0) {
      startCv = startVneed;
      endCv = endVneed;
    }
    else {

      if (startVneed > startCv) {
        startCv = startVneed;
      }
      if (endVneed < endCv) {
        endCv = endVneed;
      }
    }
  }

  if (objIsShift(workerOrContainerVehicle)) {
    var [s_start, s_end] = getShiftStartEnd(workerOrContainerVehicle, true);
    start = timeStringToMinutes(s_start);
    end = timeStringToMinutes(s_end);
  }
  else if (objIsWorker(workerOrContainerVehicle)) {
    var shift = shiftMap.get(workerOrContainerVehicle.turn);
    var extra = 0;
    var applyExtraAtStart = false;

    if (shift) {
      extra = timeStringToMinutes(shift.extraH);
      applyExtraAtStart = shift.applyExtraAtStart;

      if (shift.fromTime != workerOrContainerVehicle.shiftstart && applyExtraAtStart && extra > 0) {
        //qui il turno non inizia esattamente al fromTime del turno, ma shiftato, quindi l'extra va rimodulato in base al fromTime
        var startDelay = timeStringToMinutes(workerOrContainerVehicle.shiftstart) - timeStringToMinutes(shift.fromTime);
        extra -= startDelay;
        if (extra < 0) {
          extra = 0;
        }
      }

      if (shift.toTime != workerOrContainerVehicle.shiftend && !applyExtraAtStart && extra > 0) {
        //qui il turno non finisce esattamente al toTime del turno, ma shiftato, quindi l'extra va rimodulato in base al toTime
        var endDelay = timeStringToMinutes(shift.toTime) - timeStringToMinutes(workerOrContainerVehicle.shiftend);
        extra -= endDelay;
        if (extra < 0) {
          extra = 0;
        }
      }
    }


    start = timeStringToMinutes(workerOrContainerVehicle.shiftstart);
    end = timeStringToMinutes(workerOrContainerVehicle.shiftend);

    //Extra va considerato come EXTRA turno, quindi non "concorre" a definire l'orario di inizio e fine del turno
    if (applyExtraAtStart) {
      start += extra;
    }
    else {
      end -= extra;
    }

    realShiftStart = start;
    realShiftEnd = end;




    // start = timeStringToMinutes(workerOrContainerVehicle.servicestart);
    // end = timeStringToMinutes(workerOrContainerVehicle.serviceend);
  }
  else if (objIsVehicle(workerOrContainerVehicle)) {
    start = timeStringToMinutes(workerOrContainerVehicle.vehicleStartTime);
    end = timeStringToMinutes(workerOrContainerVehicle.vehicleEndTime);
  }
  else {
    start = timeStringToMinutes(workerOrContainerVehicle.startshift);
    end = timeStringToMinutes(workerOrContainerVehicle.endshift);
  }


  if (end < start) {
    end += 24*60;
  }

  if (startCv != 0 && endCv != 0 ) {
    var areOverlapping = (start < endCv && end > startCv);
    if (areOverlapping) {
      if (start < startCv) {
        start = startCv;
      }
      if (end > endCv) {
        end = endCv;
      }
    }
  }

  if ((end - start)<= 0 && realShiftStart > 0 && realShiftEnd > 0) {
    start = realShiftStart;
    end = realShiftEnd;
  }


  if (end < start) {
    end += 24*60;
  }
  if (end == 0 && start == 0) {
    end = h2359
  }

  // if (start > endCv || end < startCv) {
  //   start = startCv;
  //   end = endCv;
  // }

  return [start, end % (24*60)];
}


const h06 = 360;
const h12 = 720;
const h13 = 780;
const h1330 = 810;
const h1430 = 870;
const h15 = 900;
const h18 = 1080;
const h19 = 1140;
const h20 = 1200;
const h21 = 1260;
const h2030 = 1230;
const h24 = 1440;
const h2359 = 1439;


export function isNotteWithShiftMap(shiftMap: Map<string, any>, workerOrContainerVehicle: any, containerVehicle: any = null, vehicleNeed: any = null) {
  var [start, end] = getStartEndWithShiftMap(shiftMap, workerOrContainerVehicle, containerVehicle, vehicleNeed);
  //notte tutti i turni che iniziano dopo le 24 o finiscono tra le 24 e le 06

  if (end < start)
    end += h24;

  if (start === 0 && end > 0)
    return true;

  return (end > h24);
}

export function isMattinaWithShiftMap(shiftMap: Map<string, any>, workerOrContainerVehicle: any, containerVehicle: any = null, vehicleNeed: any = null) {
  var [start, end] = getStartEndWithShiftMap(shiftMap, workerOrContainerVehicle, containerVehicle, vehicleNeed);
  //mattina tutti i turni che iniziano o finiscono tra le 6 e le 12
  if (end < start && start > h12)
    return false;

  if (end < start)
    end += 24*60;

  if (start == 0 && end >= h2359) {
    return true;
  }


  return start < h12 && end > h06;
}

export function isPomeriggioWithShiftMap(shiftMap: Map<string, any>, workerOrContainerVehicle: any, containerVehicle: any = null, vehicleNeed: any = null) {
  var [start, end] = getStartEndWithShiftMap(shiftMap, workerOrContainerVehicle, containerVehicle, vehicleNeed);


  //pomeriggio tutti i turni che iniziano dopo le 12 o finiscono tra le 12 e le 18
  if (end < start)
    end += 24*60;

  if (start == 0 && end >= h2359) {
    return true;
  }

  return start < h18 && end > h15;
}

export function isSeraWithShiftMap(shiftMap: Map<string, any>, workerOrContainerVehicle: any, containerVehicle: any = null, vehicleNeed: any = null) {
  var [start, end] = getStartEndWithShiftMap(shiftMap, workerOrContainerVehicle, containerVehicle, vehicleNeed);
  //sera tutti i turni che iniziano dopo le 18 o finiscono tra le 18 e le 24

  if (end < start)
    end += 24*60;

  if (start == 0 && end >= h2359) {
    return true;
  }

  return start < h2030 && end > h21;
}

export function getTimeLabel( shiftMap: Map<string, any>, workerOrContainerVehicle: any, containerVehicle: any = null, vehicleNeed: any = null) {

  var [start, end] = getStartEndWithShiftMap(shiftMap, workerOrContainerVehicle, containerVehicle, vehicleNeed);
  if (start == 0 && end == 0) {
    return "";
  }

  var sStart = Math.floor(start / 60).toString().padStart(2, "0") + ":" + (start % 60).toString().padStart(2, "0");
  var sEnd = Math.floor(end / 60).toString().padStart(2, "0") + ":" + (end % 60).toString().padStart(2, "0");

  if (sStart == "23:59")
  {
    sStart = "00:00";
  }

  if (sEnd == "23:59")
  {
    sEnd = "00:00";
  }

  return sStart + " - " + sEnd;
}

export function minutesToTimeString(minutes: number) {
  var minutesIn24h = minutes % (24*60);

  var hours = Math.floor(minutesIn24h / 60);
  var minutesPart = minutesIn24h % 60;
  return hours.toString().padStart(2, "0") + ":" + minutesPart.toString().padStart(2, "0");
}


export function isPartialReperibility(shiftCode: string) {
  return shiftCode.startsWith("R/") || shiftCode.endsWith("/R");
}



export function isReperibility(shift: any) {
  const shiftCode = shift.shiftCode;

  if (shiftCode.startsWith("R") || shiftCode.includes("/R")) {
    // R, RN are not reperibility
    if ((shiftCode == "R") || (shiftCode=="RM")) {
      return false;
    }
    return true;
  }

  return false;
}

export function normalizeTime(time: string) {
  if (!time) {
    return "00:00";
  }
  return  minutesToTimeString(timeStringToMinutes(time));
}

export function timeSumOrSubtract(time: string, timeToAdd: string, timeToRemove: string) {
  if (!timeToAdd || timeToAdd.length == 0) {
    timeToAdd = "00:00";
  }
  if (!timeToRemove || timeToRemove.length == 0) {
    timeToRemove = "00:00";
  }

  var timeToAddHour = parseInt(timeToAdd.split(":")[0]);
  var timeToAddMinute = parseInt(timeToAdd.split(":")[1]);
  var timeToRemoveHour = parseInt(timeToRemove.split(":")[0]);
  var timeToRemoveMinute = parseInt(timeToRemove.split(":")[1]);
  var timeHour = parseInt(time.split(":")[0]);
  var timeMinute = parseInt(time.split(":")[1]);
  var newHour = timeHour + timeToAddHour - timeToRemoveHour;
  var newMinute = timeMinute + timeToAddMinute - timeToRemoveMinute;
  if (newMinute < 0) {
    newHour--;
    newMinute = 60 + newMinute;
  }

  //Return time padded with 0
  return `${newHour.toString().padStart(2, "0")}:${newMinute.toString().padStart(2, "0")}`;
}

export function getShitFromExcludedReperibility(shift: any) {
  if (!isReperibility(shift)) {
    return shift.fromTime;
  }
  const shiftCode = shift.shiftCode;
  //If shiftCode starts With R, then reperibility is before the shift (shift end - duration)

  if (!shiftCode.startsWith("R")) {
    return shift.fromTime;
  }

  return timeSumOrSubtract(shift.toTime, null, shift.time);
}

export function getShiftToExcludeReperibility(shift: any) {
  if (!isReperibility(shift)) {
    return shift.toTime;
  }
  const shiftCode = shift.shiftCode;
  //If shiftCode contains /R, then reperibility is after the shift (shift start + duration)

  if (shiftCode.startsWith("R")) {
    return shift.toTime;
  }

  return timeSumOrSubtract(shift.fromTime, shift.time, null);
}

export function getShiftStartExcludedExtra(shift: any) {
  if (!shift.applyExtraAtStart) {
    return shift.fromTime;
  }

  return timeSumOrSubtract(shift.fromTime, shift.extraH, null);
}

export function getShiftEndExcludedExtra(shift: any) {
  if (shift.applyExtraAtStart) {
    return shift.toTime;
  }

  return timeSumOrSubtract(shift.toTime, null, shift.extraH);
}

export function getShiftStartEnd(shift: any, considerReperibilitAndExtra: boolean = false) {
  var shiftStart = shift.fromTime;
  var shiftEnd = shift.toTime;

  if (considerReperibilitAndExtra) {
    if (isPartialReperibility(shift.shiftCode)) {
      shiftStart = getShitFromExcludedReperibility(shift);
      shiftEnd = getShiftToExcludeReperibility(shift);
    }
    else {
      shiftStart = getShiftStartExcludedExtra(shift);
      shiftEnd = getShiftEndExcludedExtra(shift);
    }
  }

  return [shiftStart, shiftEnd];
}


export function getWorkerStartEndForShiftInContainerVehicle(worker: any, shift: any, containerVehicle: any, considerReperibility: boolean = false) {
  var shiftStart = shift.fromTime;
  var shiftEnd = shift.toTime;
  var shiftObj: any = shift;


  if (worker && worker.overrideTurnTime) {
    var [defShiftStartTime, defShiftEndTime] = getShiftStartEnd(shift, considerReperibility);
    if ((timeStringToMinutes(worker.shiftstart) != timeStringToMinutes(defShiftStartTime)) || (timeStringToMinutes(worker.shiftend) != timeStringToMinutes(defShiftEndTime))) {
      //in questo caso il worker ha degli orari diversi dallo shift, quindi creo un shift fittizio con i suoi orari

      shiftObj = JSON.parse(JSON.stringify(shift));
      shiftObj.fromTime = minutesToTimeString(timeStringToMinutes(worker.shiftstart));
      shiftObj.toTime = minutesToTimeString(timeStringToMinutes(worker.shiftend));
      shiftObj.time = minutesToTimeString(timeStringToMinutes(worker.shiftend) - timeStringToMinutes(worker.shiftstart));
    }
  }


  [shiftStart, shiftEnd] = getShiftStartEnd(shiftObj, considerReperibility);

  var shiftStartTime = timeStringToMinutes( shiftStart );
  var shiftEndTime = timeStringToMinutes( shiftEnd );


  if (shiftEndTime < shiftStartTime) {
    shiftEndTime += 24*60;
  }

  if (containerVehicle != null) {
    var cvStartTime = timeStringToMinutes(containerVehicle.startshift);
    var cvEndTime = timeStringToMinutes(containerVehicle.endshift);

    if (cvEndTime < cvStartTime) {
      cvEndTime += 24*60;
    }


    //if shiftStart is greater than startTime, we need to set it to shiftStart
    if (shiftStartTime < cvStartTime) {
      shiftStartTime = cvStartTime;
    }

    //if shiftEnd is less than endTime, we need to set it to shiftEnd
    if (shiftEndTime > cvEndTime) {
      shiftEndTime = cvEndTime;
    }

    //if startTime equals "23:59" we need to set it to "00:00" (after checking if shiftStart is greater than startTime)
    if (shiftStartTime == h2030) {
      shiftStartTime = 0;
    }

  }

  return [minutesToTimeString(shiftStartTime), minutesToTimeString(shiftEndTime)];
}
//   String shiftStart = shift.getFromTime();
//                     String shiftEnd = shift.getToTime();

//                     if (isReperibility(shift)) {
//                         shiftStart = getShitFromExcludedReperibility(shift);
//                         shiftEnd = getShiftToExcludeReperibility(shift);
//                     }

//                     String startTime = shiftStart;
//                     String endTime = shiftEnd;

//                     if (dailyContainerVehicle != null) {
//                       startTime = dailyContainerVehicle.getStartshift();
//                       endTime = dailyContainerVehicle.getEndshift();

//                       //Hours are set in 00:00:00, we need to remove the seconds
//                       if (startTime.length() > 5) {
//                         startTime = startTime.substring(0, 5);
//                       }
//                       if (endTime.length() > 5) {
//                         endTime = endTime.substring(0, 5);
//                       }



//                       //if endTime equals "23:59" we need to set it to "00:00" (before checking if shiftEnd is less than endTime)
//                       if (endTime.equals("23:59")) {
//                         endTime = "00:00";
//                       }

//                       //if shiftStart is greater than startTime, we need to set it to shiftStart
//                       if (shiftStart.compareTo(startTime) > 0) {
//                         startTime = shiftStart;
//                       }

//                       //if shiftEnd is less than endTime, we need to set it to shiftEnd
//                       if (shiftEnd.compareTo(endTime) < 0) {
//                         endTime = shiftEnd;
//                       }

//                       //if startTime equals "23:59" we need to set it to "00:00" (after checking if shiftStart is greater than startTime)
//                       if (startTime.equals("23:59")) {
//                         startTime = "00:00";
//                       }

//                     }
// }
export function getServiceToRecord(serviceOrContainerVehicle: string, shift: string) {
    if (!serviceOrContainerVehicle) return "";

    if (shift == WorkerServiceTurnModal.REP_SHIFT) {
      return WorkerServiceTurnModal.REPERIBILE_SERVICE
    }
    if (shift == WorkerServiceTurnModal.REST_SHIFT) {
      return WorkerServiceTurnModal.REST_SERVICE
    }
    if (shift == WorkerServiceTurnModal.SMONTO_SHIFT) {
      return WorkerServiceTurnModal.SMONTO_SERVICE
    }
    if (shift == WorkerServiceTurnModal.DAILY_ABSENCE_GR) {
      return WorkerServiceTurnModal.DAILY_ABS_SERVICE
    }
    if (shift == WorkerServiceTurnModal.DAILY_ABSENCE_AR) {
      return WorkerServiceTurnModal.DAILY_ABS_SERVICE
    }
    if (shift == WorkerServiceTurnModal.HOLIDAYS_ABSENCE) {
      return WorkerServiceTurnModal.FERIE_SERVICE
    }

    const splitContainerVehicle = serviceOrContainerVehicle.split("_");
    const length = splitContainerVehicle.length;
    const isContainerVehicleSelected = length == 3;
    const isSpecialServiceSelected = length == 1;
    if (isContainerVehicleSelected || isSpecialServiceSelected) {
      return splitContainerVehicle[0].toUpperCase();
    }
    return "";
  }
