import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { DateTime } from 'luxon';
import Button from '../../components/Button/Button';
import Select from '../../components/Select/Select';
import TimeInputPicker from '../../components/TimeInputPicker/TimeInputPicker';
import ShiftUtils from '../../utils/ShiftUtils';
import NeedManagementApi from '../../api/need/NeedManagementApi';
import { NeedDto } from '../../api/entity/NeedDto';
import GfDateUtils from '../../components/DatePicker/GfDateUtils';
import I18n from '../../lib/I18n';
import NeedValidator from '../../api/worker/validator/NeedValidator';
import { OperationResult } from '../../api/entity/OperationResult';
import ContainerVehicleApi from '../../api/container-vehicle/ContainerVehicleApi';
import { DailyContainerVehicle } from '../../api/entity/response/nt/ContainerVehicleResponseNt';
import RadioCodeApi from '../../api/radio/RadioCodeApi';
import { RadioCodeDto } from '../../api/entity/RadioCodeDto';
import CreatableSelect from 'react-select/creatable';
import ContainerRadioVehicleDailyApi from '../../api/container-vehicle/ContainerRadioVehicleDailyApi';

interface SingleNeedModalProps {
  isOpen: boolean;
  onClose: () => void;
  currentDate: DateTime;
  selectedCircuit: string;
  onSuccess: () => void;
}

const modalStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
  overlay: { zIndex: 10000 },
};

interface Selection {
  label: string,
  value: string
}

export const SingleNeedModal: React.FC<SingleNeedModalProps> = ({
  isOpen,
  onClose,
  currentDate,
  selectedCircuit,
  onSuccess
}) => {
  const shiftUtils = new ShiftUtils();
  const needApi = new NeedManagementApi();
  const containerVehiclesApi = new ContainerVehicleApi();
  const dailyContainerVehicleApi = new ContainerRadioVehicleDailyApi();
  const radioCodeApi = new RadioCodeApi();

  // Form state
  const [circuit, setCircuit] = useState(selectedCircuit || shiftUtils.CIRCUIT_DTO[0]);
  const [startTime, setStartTime] = useState("00:00");
  const [endTime, setEndTime] = useState("24:00");
  const [category, setCategory] = useState(shiftUtils.NEED_TOPOLOGY_DTO[0]);
  const [convention, setConvention] = useState(shiftUtils.NEED_CONVENTION_DTO[0]);
  const [publishing, setPublishing] = useState(shiftUtils.PUBLISHING_DTO[0]);
  const [parking, setParking] = useState(shiftUtils.PLACE_DTO[0]);
  const [isLoading, setIsLoading] = useState(false);

  // Radio code state
  const [radioCodes, setRadioCodes] = useState<RadioCodeDto[]>([]);
  const [selectedRadioCode, setSelectedRadioCode] = useState<RadioCodeDto>({ id: 0, name: '' });

  // Load radio codes
  useEffect(() => {
    if (isOpen) {
      const loadRadioCodes = async () => {
        try {
          const response = await radioCodeApi.getAllRadioCodes();
          if (response && response.content) {
            const allCodes = [radioCodeApi.getEmptyRadioCode(), ...response.content];
            setRadioCodes(allCodes);
            setSelectedRadioCode(allCodes[0]);
          }
        } catch (error) {
          console.error("Failed to load radio codes:", error);
        }
      };

      loadRadioCodes();
    }
  }, [isOpen]);

  // Reset form when modal opens
  useEffect(() => {
    if (isOpen) {
      setCircuit(selectedCircuit || shiftUtils.CIRCUIT_DTO[0]);
      setStartTime("00:00");
      setEndTime("24:00");
      setCategory(shiftUtils.NEED_TOPOLOGY_DTO[0]);
      setConvention(shiftUtils.NEED_CONVENTION_DTO[0]);
      setPublishing(shiftUtils.PUBLISHING_DTO[0]);
      setParking(shiftUtils.PLACE_DTO[0]);
    }
  }, [isOpen, selectedCircuit]);

  // Handle radio code selection or creation
  const handleRadioCodeChange = (newValue: Selection) => {
    const value = Number(newValue.value);
    const isExistingAlready = !isNaN(value);

    if (isExistingAlready) {
      setSelectedRadioCode({ id: value, name: newValue.label });
    } else {
      setSelectedRadioCode({ id: null, name: newValue.label });
    }
  };

  //function that return a three alphanumeric character string based on the current second of the day
  const mappingChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789.";
  const getSecondsOfDay = () => {
    const now = new Date();
    let seconds = now.getHours() * 3600 + now.getMinutes() * 60 + now.getSeconds();
    const base = mappingChars.length;
    let result = "";
    while (seconds > 0) {
      result = mappingChars[seconds % base] + result;
      seconds = Math.floor(seconds / base);
    }


    return result.padStart(3, '0');
  }

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      // Generate service code: H<yy><mm><dd>##
      const formattedDate = currentDate.toFormat("yyMMdd") + getSecondsOfDay();

      const needVehicleDtoList = [{
        id: null,
        numberVehiclesFestive: ["1", "1", "1", "1", "1", "1", "1"],
        category: category,
        numberVehiclesWorkdays: ["1", "1", "1", "1", "1", "1", "1"],
        publishing: publishing,
        parkingDescription: parking,
        fromTime: startTime,
        toTime: endTime,
        convention: convention,
        needId: null
      }];

      // Save radio code if needed
      let radioCodeId = selectedRadioCode.id || 0;
      if (selectedRadioCode.name && (!selectedRadioCode.id || selectedRadioCode.id === 0) &&
          selectedRadioCode.name !== I18n.get().RadioCodeRegistry.modal.nothingOption) {

        const radioResponse = await radioCodeApi.saveRadioCode({
          id: selectedRadioCode.id,
          name: selectedRadioCode.name
        });

        if (radioResponse.status === "OK") {
          radioCodeId = radioResponse.content[0].id;
        }
      }



      // Create need with default values
      const needDto: NeedDto = {
        circuit: circuit,
        serviceCode: `H${formattedDate}`,  // Will be completed with ## by the backend
        startDate: currentDate.toFormat(GfDateUtils.STORED_DATE_FORMAT),
        endDate: currentDate.toFormat(GfDateUtils.STORED_DATE_FORMAT),
        id: null,
        note: "Creato da DailyOverview",
        needVehicleDtoList: needVehicleDtoList,
        needHumanDtoList: [],
        hidden: true
      };

      // Save need
      const needResponse = await needApi.upsert(needDto);
      if (needResponse.status == OperationResult.OK) {
        const savedNeed = needResponse.content[0];

        // Connect vehicle to need
        const connectResult = await needApi.connectVehicleToNeed({
          needId: savedNeed.id,
          needVehicles: needVehicleDtoList,
          deletedVehicles: []
        });

        if (connectResult.status == OperationResult.OK) {
            const needVehicleId = connectResult.content[0].needVehicleDtoList[0].id;

            //create container vehicle with radio code
            const containerVehicle: DailyContainerVehicle = {
                id: null,
                containervehicle: needDto.serviceCode + "_" + needVehicleId + "_1",
                date: currentDate.toFormat(GfDateUtils.STORED_DATE_FORMAT),
                category: category,
                service: needDto.serviceCode,
                startshift: startTime,
                endshift: endTime,
                radiocodeid: radioCodeId,
            };



            const result = await containerVehiclesApi.create(containerVehicle);
            if (result.status == OperationResult.OK) {
                if (radioCodeId > 0) {
                  console.log("Selected radio code:", selectedRadioCode);
                  await dailyContainerVehicleApi.updateRadioIdForContainerVehicle(containerVehicle.containervehicle, radioCodeId);
                }

                onSuccess();
                onClose();
            } else {
                console.error("Failed to create container vehicle:", result);
                onClose();
            }
        } else {
            await needApi.delete(savedNeed.id);
            console.error("Failed to connect vehicle to need:", connectResult);
            onClose();
        }

      }
    } catch (error) {
      console.error("Failed to create need:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Personalizza le etichette per le convenzioni
  const conventionsWithLabels = shiftUtils.NEED_CONVENTION_LIST;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={modalStyle}
      contentLabel="Crea Fabbisogno"
    >
      <div className="modal-content">
        <h2>Crea Fabbisogno Giornaliero</h2>

        <div className="modal-row">
          <label>
            <span>Circuito:</span>
            <Select
              items={shiftUtils.CIRCUIT_DTO}
              defaultValue={shiftUtils.CIRCUIT_DTO.indexOf(circuit)}
              onChange={(index) => setCircuit(shiftUtils.CIRCUIT_DTO[index])}
            />
          </label>
        </div>

        <div className="modal-row">
          <div className="time-inputs">
            <div className="time-input">
              <label>Ora inizio:</label>
              <TimeInputPicker
                defaultValue={startTime}
                onCorrectChange={(value) => setStartTime(value)}
              />
            </div>
            <div className="time-input">
              <label>Ora fine:</label>
              <TimeInputPicker
                defaultValue={endTime}
                onCorrectChange={(value) => setEndTime(value)}
              />
            </div>
          </div>
        </div>

        <div className="modal-row">
          <label>
            <span>Tipologia:</span>
            <Select
              items={shiftUtils.NEED_TOPOLOGY_DTO}
              defaultValue={shiftUtils.NEED_TOPOLOGY_DTO.indexOf(category)}
              onChange={(index) => setCategory(shiftUtils.NEED_TOPOLOGY_DTO[index])}
            />
          </label>
        </div>

        <div className="modal-row">
          <label>
            <span>Codice Radio:</span>
            <CreatableSelect
              closeMenuOnSelect={true}
              onChange={(newValue) => handleRadioCodeChange(newValue)}
              options={radioCodes.map(option => ({
                value: option.id,
                label: option.name
              }))}
              value={{
                value: selectedRadioCode.id,
                label: selectedRadioCode.name
              }}
            />
          </label>
        </div>

        <div className="modal-row">
          <label>
            <span>Convenzione:</span>
            <Select
              items={conventionsWithLabels}
              defaultValue={shiftUtils.NEED_CONVENTION_DTO.indexOf(convention)}
              onChange={(index) => setConvention(shiftUtils.NEED_CONVENTION_DTO[index])}
            />
          </label>
        </div>

        <div className="modal-row">
          <label>
            <span>Pubblicazione:</span>
            <Select
              items={shiftUtils.PUBLISHING_DTO}
              defaultValue={shiftUtils.PUBLISHING_DTO.indexOf(publishing)}
              onChange={(index) => setPublishing(shiftUtils.PUBLISHING_DTO[index])}
            />
          </label>
        </div>

        <div className="modal-row">
          <label>
            <span>Postazione:</span>
            <Select
              items={shiftUtils.PLACE_DTO}
              defaultValue={shiftUtils.PLACE_DTO.indexOf(parking)}
              onChange={(index) => setParking(shiftUtils.PLACE_DTO[index])}
            />
          </label>
        </div>

        <div className="modal-row buttons">
          <Button secondary={true} onClick={onClose}>
            Annulla
          </Button>
          <Button onClick={handleSubmit} disabled={isLoading}>
            {isLoading ? "Creazione..." : "Conferma"}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
